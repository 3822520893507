export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const bciImage = require("../assets/bci.png");
export const  aboveBannerImage =require( "../assets/aboveBannerImage.png");
export const logo=require("../assets/aboveBannerImage.png")
export const mobileBelowImage=require("../assets/mobileBelowImage.png")
export const unCheckedBox = require("../assets/unCheckedBox.png");
export const checkedBox = require("../assets/checkedBox.png");

