import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import React from "react";
export interface FormValues {
  forkliftBrand: string;
  forkliftModel: string;
  batterySize:{
    width?: string;
    length?: string;
    height?: string;
  };
  batteryModel:string;
  batteryDimensions:{
    width?: string;
    length?: string;
  };
  buildingVolts: string;
  chargerVoltage:string;
  phase:string;
  chargerConnector:string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  initialValues:FormValues;
  chargerModel:{value:string,errorMessage:string}
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  postExistingTruckApiCallId:string = "";
  singleTextFields: { qNo: number, question: string, name: string, placeHolder: string }[] = [
    { qNo: 1, question: configJSON.forkliftBrand, name: "forkliftBrand", placeHolder: configJSON.answerText },
    { qNo: 2, question: configJSON.forkliftModel, name: "forkliftModel", placeHolder: configJSON.answerText },
    { qNo: 4, question: configJSON.batteryModel, name: "batteryModel", placeHolder: configJSON.answerText },
    { qNo: 9, question: configJSON.chargersModel, name: "chargersModel", placeHolder: configJSON.answerText },
    { qNo: 10, question: configJSON.chargerConnector, name: "chargerConnector", placeHolder: configJSON.answerText },
  ];
  multipleTextFields = [
    {
      qNo: 3,
      question: configJSON.batterySize,
      parentFieldName: "batterySize",
      subFields: [
        {
          childFieldName: "width",
          placeHolderAndLabel: "Width"
        },
        {
          childFieldName: "length",
          placeHolderAndLabel: "Length"
        },
        {
          childFieldName: "height",
          placeHolderAndLabel: "Height"
        }
      ]
    },
    {
      qNo: 5,
      question: configJSON.batteryDimensions,
      parentFieldName: "batteryDimensions",
      subFields: [
        {
          childFieldName: "width",
          placeHolderAndLabel: "Width"
        },
        {
          childFieldName: "length",
          placeHolderAndLabel: "Length"
        },

      ]
    }
  ]
  radioGroups = [{
    qNo: 6,
    question: configJSON.buildingVolts,
    parentFieldName: "buildingVolts",
    options: [
      { value: '240', label: '240 Volts' },
      { value: '440', label: '440 Volts' },
      { value: '480', label: '480 Volts' },
      { value: '600', label: '600 Volts' },
    ]
  },
  {
    qNo: 7,
    question: configJSON.chargerVoltage,
    parentFieldName: "chargerVoltage",
    options: [
      { value: '12', label: '12 Volts' },
      { value: '24', label: '24 Volts' },
      { value: '36', label: '36 Volts' },
      { value: '48', label: '48 Volts' },
      { value: '72', label: '72 Volts' },
      { value: '84', label: '84 Volts' },
    ]
  },
  {
    qNo: 8,
    question: configJSON.phase,
    parentFieldName: "phase",
    options: [
      { value: 'Single Phase', label: 'Single Phase' },
      { value: '3 Phase', label: '3 Phase' },
    ]
  }
  ]
  chargerModels = [
    "Model 1 - G","Model 1 - GP","Model 2 - G","Model 2 - GP"
   ]
   validationSchema = Yup.object().shape({
    forkliftBrand: Yup.string().required('*required field'),
    forkliftModel: Yup.string().required('*required field'),
    batterySize: Yup.object().shape({
      width: Yup.number().required('*required field'),
      length: Yup.number().required('*required field'),
      height: Yup.number().required('*required field'),
    }),
    batteryModel: Yup.string().required('*required field'),
    batteryDimensions: Yup.object().shape({
      width: Yup.number().required('*required field'),
      length: Yup.number().required('*required field'),
    }),
    buildingVolts: Yup.string().required('*required field'),
    chargerVoltage: Yup.string().required('*required field'),
    phase: Yup.string().required('*required field'),
    chargerConnector: Yup.string().required('*required field'),
  });
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      initialValues:{
        forkliftBrand: '',
        forkliftModel: '',
        batterySize: { width: '', length: '', height: '' },
        batteryModel: "",
        batteryDimensions: { width: '', length: '' },
        buildingVolts: '',
        chargerVoltage: "",
        phase: "",
        chargerConnector: ''
      },
      chargerModel:{value:"",errorMessage:""}
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({teamName:"example"})
    // Customizable Area End
  }

  // Customizable Area Start
  handleChargerModal = (event: React.SyntheticEvent, modelValue: string | null) => {
    this.setState({
      chargerModel: {
        value: modelValue!,
        errorMessage: Boolean(modelValue) ? "" : configJSON.requiredField
      }
    })
  }
  handleOnblurChargerModal = () => {
    if (!this.state.chargerModel.value) {
      this.setState((prev) => ({ ...prev, chargerModel: { value: prev.chargerModel.value, errorMessage: configJSON.requiredField } }))
    }
  }
  handleSubmit = (values: FormValues) => {
    if (this.state.chargerModel.value) {
      console.log(this.state.chargerModel.value, "value")
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CreateClientAccessories");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
    }
  };
  condition = (first: boolean, second: boolean, third: boolean) => {
    if (first || second || third) return configJSON.requiredField
  }
  conditionTwoUndefine=(first:boolean|undefined,second:string|undefined)=>{
    if(first&&second){
      return true
    }else{
      return false
    }
  }
  conditionOneValue=(booleanValue:boolean,value:string|undefined)=>{
    if(booleanValue) return value
  }
  conditionTwoBooleans=(first:boolean,second:boolean)=>{
    if(first&&second){
      return true
    }else{
      return false
    }
  }
  conditionTwoValues = (first: boolean, second: boolean) => {
    if (first || second) return configJSON.requiredField
  }
   // Customizable Area End
}
