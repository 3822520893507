Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.getAnalyticDashboardCallId = "/bx_block_analytics7/analytics?month=2024-09";
exports.btnExampleTitle = "CLICK ME";
exports.applyFilter="Apply Filter";
exports.filters="Filters";
exports.day='Day';
exports.week="Week";
exports.month="Month";
exports.year="Year";
exports.analytics="Analytics";
exports.filter="Filter";
exports.analyticsDescription="View your total quotes, sold quotes, revenue from sales, and average quote price—all in one convenient place.";
exports.pleaseSelectAFilterOption="Please select a filter option."
// Customizable Area End