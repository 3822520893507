import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import {  toast } from 'react-toastify';
 // Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dashBoardData: any;
  txtInputValue: string;
  currentFilter:"Ideal"|"Day"|"Week"|"Month"|"Year";
  dateValue:Date | null |string| [Date | null|string, Date | null|string];
  isRangeSelected:boolean;
  isAnalyticsScreen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAnalyticDashboardDataCallId: string = "";
  cardStaticData: { title: string, numberCount: string }[] = [
    {
      numberCount: "1.200",
      title: "Total Quotes",
    },
    {
      numberCount: "800",
      title: "Quotes Sold",
    },
    {
      numberCount: "120k",
      title: "Total Revenue",
    },
    {
      numberCount: "1k",
      title: "Quote Price",
    }

  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationTargetMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      dashBoardData: "",
      txtInputValue: "",
      currentFilter:"Ideal",
      dateValue:null,
      isRangeSelected:false,
      isAnalyticsScreen:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getAnalyticDashboardDataCallId === apiRequestCallId) {
        this.setState({ dashBoardData: responseJson })
      }
    };
    // Customizable Area End
  }
  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getAnalyticDashboardData() 
  };

  formatNumber(value: any) {
    if (value >= 1000) {
      return `${Math.floor(value / 1000)}k`;
    }
    return value;
  }

  getAnalyticDashboardData = async () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticDashboardDataCallId = requestMessage.messageId;
    let endpoint = configJSON.getAnalyticDashboardCallId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToAnyPage = (pathUrl: string) => {
    const gotoOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
    gotoOrders.addData(getName(MessageEnum.NavigationTargetMessage), pathUrl);
    gotoOrders.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(gotoOrders);
  }

  handleFilter = () => {
    this.setState({ isAnalyticsScreen: !this.state.isAnalyticsScreen, currentFilter: "Ideal" })
  }
  handleApplyFilter = () => {
    const { currentFilter } = this.state
    if (currentFilter === "Ideal") {
      toast.error(configJSON.pleaseSelectAFilterOption)
    } else {
      this.setState({ isAnalyticsScreen: !this.state.isAnalyticsScreen })
    }
  }
  handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateFormat = (date: Date | number) => moment(date).format("YYYY-MM-DD")
    const todayDate = new Date()
    const sevenDaysAgo = new Date().setDate(todayDate.getDate() - 6)
    if(event.target.value==="Week"){
      this.setState({
        currentFilter: event.target.value as S["currentFilter"],
        isRangeSelected: true, dateValue: [dateFormat(sevenDaysAgo), dateFormat(todayDate)]
      });
    }else{
      this.setState({
        currentFilter: event.target.value as S["currentFilter"],
        isRangeSelected: false, dateValue: todayDate
      })
    }
    
  }
  handleRemoveDateValue = () => {
    this.setState({ dateValue: null })
  }
  handleDateValueSwitch = (date: S["dateValue"]) => {
    const { currentFilter } = this.state
    switch (currentFilter) {
      case "Day":
        return moment(date&&date.toString()).format("DD MMMM")
      case "Week":
        return Array.isArray(date) && `From ${moment(date[0]).format("DD MMMM")} to ${moment(date[1]).format("DD MMMM")}`
      case "Month":
        return moment(date&&date.toString()).format("MMMM")
      case "Year":
        return moment(date&&date.toString()).format("YYYY")
    }
  }
  changeDate = (dateValue: S["dateValue"]) => {
    this.setState({ dateValue: dateValue })
  };
  handleDays=(_: unknown, date:Date) => ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()]
  // Customizable Area End
}
