Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.surveyApiEndPoint = "bx_block_surveys/surveys"
exports.surveyQuestionApiEndPoint = "bx_block_surveys/questions"
exports.surveyQuestionSubmissionsApiEndPoint = "bx_block_surveys/submissions"
exports.profilePictureOptional="Profile Picture (optional)";
exports.uploadImage="Upload Image";
exports.createClient="bx_block_profile/clients";
exports.applyFilter="Apply Filter";
exports.filters="Filters";
exports.status="Status";
exports.total="Total";
exports.day='Day';
exports.week="Week";
exports.month="Month";
exports.year="Year";
exports.analytics="Analytics";
exports.filter="Filter";
exports.sold="Sold";
exports.pending="Pending";
exports.declined="Declined";
exports.pleaseSelectAFilterOption="Please select a filter option"
// Customizable Area End