import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  mainStep:number;
  messageStep:"sendTheQuote"|"sendTheQuoteSuccess"|"reSendTheQuote"|"reSendTheQuoteSuccess"|"editTheQuote"|"changeTheStatus";
  statusValue:string;
  status:string;
  isQuoteSentAlready:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmaildeliverabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      mainStep:0,
      messageStep:"sendTheQuote",
      statusValue:"Sent",
      status:"Sent",
      isQuoteSentAlready:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  quoteTableHeadings = ["QUANT", "PART NUMBER", "DESCRIPTION", "PRICE EA", "AMOUNT"];
  statusChanges = ["Draft", "Sent", "Pending", "Declined", "Sold"]
  colorCodes=["#57534E","#455992","#F59E0B","#C7161D","#1CAB6B"]
  createData = (
    name: number,
    calories: string,
    fat: string,
    carbs: number,
    protein: number,
  ) => {
    return { name, calories, fat, carbs, protein };
  }
  rows = [
    this.createData(6655, `8FBE20U (36V-25")`, `FACTORY SPECIFICATIONS (MFG/MODEL):
  TOYOTA I   8FBE20U (36V-25") w/wo rollers
  Voltage: 36 Comp Dims:   39.20 X 25.10   X 24.50
  Min Battery Weight: 2105 lbs   Cover Req'd: No Cable POS/LL: B-10 Conn: 6320-SB350 Gray
  ---18-FLX.660-37 *38.25" X 24.50" X 22.62*
  310B64P01T2-SBKN---`, 24, 4.0),
    this.createData(6655, `8FBE20U (36V-25")`, `FACTORY SPECIFICATIONS (MFG/MODEL):
  TOYOTA I   8FBE20U (36V-25") w/wo rollers
  Voltage: 36 Comp Dims:   39.20 X 25.10   X 24.50
  Min Battery Weight: 2105 lbs   Cover Req'd: No Cable POS/LL: B-10 Conn: 6320-SB350 Gray
  ---18-FLX.660-37 *38.25" X 24.50" X 22.62*
  310B64P01T2-SBKN---`, 24, 4.0),
    this.createData(6655, `8FBE20U (36V-25")`, `FACTORY SPECIFICATIONS (MFG/MODEL):
  TOYOTA I   8FBE20U (36V-25") w/wo rollers
  Voltage: 36 Comp Dims:   39.20 X 25.10   X 24.50
  Min Battery Weight: 2105 lbs   Cover Req'd: No Cable POS/LL: B-10 Conn: 6320-SB350 Gray
  ---18-FLX.660-37 *38.25" X 24.50" X 22.62*
  310B64P01T2-SBKN---`, 24, 4.0),
  ];
  messageObj = {
    sendTheQuote: {
      messageHeading: configJSON.sendTheQuote,
      messageContent: configJSON.sendTheQuoteBelowText,
      buttonText: configJSON.yesSendQuote
    },
    sendTheQuoteSuccess: {
      messageHeading: configJSON.sendTheQuoteSuccess,
      messageContent: configJSON.sendTheQuoteSuccessBelowText,
      buttonText: configJSON.continue
    },
    reSendTheQuote: {
      messageHeading: configJSON.reSendTheQuote,
      messageContent: configJSON.reSendQuoteBelowText,
      buttonText: configJSON.yesResendQuote
    },
    reSendTheQuoteSuccess: {
      messageHeading: configJSON.reSendTheQuoteSuccess,
      messageContent: configJSON.reSendTheQuoteSuccessBelowText,
      buttonText: configJSON.continue
    },
    editTheQuote: {
      messageHeading: configJSON.editTheQuote,
      messageContent: configJSON.editTheQuoteBelowText,
      buttonText: configJSON.yesEditQuote
    },
    changeTheStatus: {
      messageHeading: configJSON.changeTheStatus,
      messageContent: configJSON.changeTheStatusBelowText,
      buttonText: configJSON.yesChangeStatus
    },
  }
  handleNavigate = (path: string) => {
    const  message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData( getName(MessageEnum.NavigationTargetMessage), path);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
  };
  handleMessageButton = () => {
    switch (this.state.messageStep) {
      case "sendTheQuote":
        this.setState({ mainStep: 2, messageStep: "sendTheQuoteSuccess" })
        break;
      case "sendTheQuoteSuccess":
        this.handleNavigate("PreviousQuotes")
        break;
      case "reSendTheQuote":
        this.setState({ mainStep: 2, messageStep: "reSendTheQuoteSuccess" })
        break;
      case "reSendTheQuoteSuccess":
        this.handleNavigate("PreviousQuotes")
        break;
      case "editTheQuote":
        this.handleNavigate("CreateClient")
        break;
      case "changeTheStatus":
        this.setState({ mainStep: 0,status:this.state.statusValue })
        break;
    }
  }
  handleStatusChange = () => {
    this.setState({ mainStep: 1 })
  }
  handleGoBack = () => {
    this.setState({ mainStep: 0,statusValue:this.state.status })
  }
  handleMassageNavigate = (messageStep: S["messageStep"]) => {
    this.setState({ mainStep: 2, messageStep: messageStep })
  }
  handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ statusValue: event.target.value })
  }
  goBackButtonCondition = () => {
    const { messageStep } = this.state
    return messageStep === "sendTheQuote" || messageStep === "reSendTheQuoteSuccess" ? false : true
  }
  // Customizable Area End
}
