import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
type bgColorType={[key:string]:string};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any;
    search: string;
    currentFilter: "Ideal" | "Day" | "Week" | "Month" | "Year" | "Total";
    dateValue: Date | null | string | [Date | null | string, Date | null | string];
    isRangeSelected: boolean;
    isPreviousQuoteScreen: boolean;
    statusOptions:{[key:string]:boolean}
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PreviousQuotesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetDataCallId: string = "";
    quoteBgColors:bgColorType={
      pending:"#455992",
      edited:"#57534E",
      decline:"#C7161D",
      sold:"#1CAB6B"
    }
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            search: "",
            currentFilter:"Ideal",
            dateValue: null,
            isRangeSelected: false,
            isPreviousQuoteScreen: true,
            statusOptions: {
                sold: false,
                pending: false,
                declined: false
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    getSearchData = (event:any) => {
        this.setState({
            search:event.target.value
        })
    }
    navigateToAnyPage = (pathUrl: string,quoteId?:number) => {
        const gotoOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
        gotoOrders.addData(getName(MessageEnum.NavigationTargetMessage), pathUrl);
        gotoOrders.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(gotoOrders);

        if(quoteId) setStorageData("quoteId",quoteId)
      }
     
      handleFilter = () => {
        this.setState({
          isPreviousQuoteScreen: !this.state.isPreviousQuoteScreen,
          currentFilter: "Ideal",
          statusOptions: {
            sold: false,
            pending: false,
            declined: false
          }
        })
      }
      handleApplyFilter = () => {
        const { currentFilter } = this.state
        if (currentFilter === "Ideal") {
          toast.error(configJSON.pleaseSelectAFilterOption)
        } else {
          this.setState({
            isPreviousQuoteScreen: !this.state.isPreviousQuoteScreen})
        }
      }
      handleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dateFormat = (date: Date | number) => moment(date).format("YYYY-MM-DD")
        const todayDate = new Date()
        const sevenDaysAgo = new Date().setDate(todayDate.getDate() - 6)
        if (event.target.value === "Total") {
        return this.setState({
            currentFilter: event.target.value as S["currentFilter"],
            isRangeSelected: false, dateValue: null
          })
        } 
        if(event.target.value==="Week"){
          this.setState({
            currentFilter: event.target.value as S["currentFilter"],
            isRangeSelected: true, dateValue: [dateFormat(sevenDaysAgo), dateFormat(todayDate)]
          });
        }else{
          this.setState({
            currentFilter: event.target.value as S["currentFilter"],
            isRangeSelected: false, dateValue: todayDate
          })
        }
      }
      handleRemoveDateValue = () => {
        this.setState({ dateValue: null })
      }
      handleDateValueSwitch = (date: S["dateValue"]) => {
        const { currentFilter } = this.state
        switch (currentFilter) {
          case "Day":
            return moment(date&&date.toString()).format("DD MMMM")
          case "Week":
            return Array.isArray(date) && `From ${moment(date[0]).format("DD MMMM")} to ${moment(date[1]).format("DD MMMM")}`
          case "Month":
            return moment(date&&date.toString()).format("MMMM")
          case "Year":
            return moment(date&&date.toString()).format("YYYY")
        }
      }
      changeDate = (dateValue: S["dateValue"]) => {
        this.setState({ dateValue: dateValue })
      };
      handleStatusCheckBox= (checked:boolean,statusKey:string) => {
         this.setState((prev)=>({statusOptions:{...prev.statusOptions,[statusKey]:checked}}));
      }
      handleDays=(_: unknown, date:Date) => ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()];
      quotesData=[
        {title:"Toyota Lift of South Texas",
          quoteId:"#0000070924",
          date:"24/22/2024",
          to:"Warehouse HQ",
          status:"sold"
        },
        {title:"Toyota Lift of South Texas",
          quoteId:"#0000070924",
          date:"24/22/2024",
          to:"Warehouse HQ",
          status:"pending"
        },
        {title:"Toyota Lift of South Texas",
          quoteId:"#0000070924",
          to:"Warehouse HQ",
          date:"24/22/2024",
          status:"edited"
        },
        {title:"Toyota Lift of South Texas",
          quoteId:"#0000070924",
          to:"Warehouse HQ",
          date:"24/22/2024",
          status:"decline"
        },
        {title:"Toyota Lift of South Texas",
          quoteId:"#0000070924",
          to:"Warehouse HQ",
          date:"24/22/2024",
          status:"sold"
        },
        {title:"Toyota Lift",
          quoteId:"#0000070924",
          to:"Warehouse HQ",
          date:"24/22/2024",
          status:"sold"
        }
      ]
    // Customizable Area End

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area End
}
