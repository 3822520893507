import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface StaticMonthData {
    name: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any;
    initialValues: {
        shiftOne: string;
        shiftTwo: string;
        shiftThree: string;
        shiftFour: string;
        shiftFive: string;
        shiftSix: string;
        NormalSeason: string;
        HighSeason: string;
        region: string;
        months: string[];
        quote:string
    };
    staticMonthData: StaticMonthData[];
    selectedValue:boolean;
    selectedValue2:boolean;
    showInnerBox:boolean;
    activeBox:number|null
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SurveyFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetDataCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            initialValues: {
                shiftOne: "",
                shiftTwo: "",
                shiftThree: "",
                shiftFour: "",
                shiftFive: "",
                shiftSix: "",
                NormalSeason: "",
                HighSeason: "",
                region: "",
                months: [],
                quote:""
            },
            staticMonthData: [
                {
                    name: "January",
                },
                {
                    name: "Febuary",
                },

                {
                    name: "March",
                },

                {
                    name: "April",
                },

                {
                    name: "May",
                },

                {
                    name: "June",
                },

                {
                    name: "July",
                },

                {
                    name: "August",
                },

                {
                    name: "September",
                },

                {
                    name: "Octuber",
                },

                {
                    name: "November",
                },

                {
                    name: "December",
                },
            ],
            selectedValue:false,
            selectedValue2:false,
            showInnerBox:false,
            activeBox:null


            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    handleChange = (event:any   ) => {
        let  {name, checked} = event.target
        if(name === "customradio"){
            this.setState({ selectedValue: checked, selectedValue2: false });
        }else{
            this.setState({ selectedValue2: checked, selectedValue: false });

        }
    };

    isChecked = (value: string, months: string[]) => {
        return months.includes(value);
      };
    
      handleCheckboxChange = (value: string, setFieldValue: (field: string, value: any) => void, months: string[]) => {
        const newMonths = months.includes(value)
          ? months.filter(month => month !== value)
          : [...months, value]; 
        setFieldValue('months', newMonths); 
      };

    // Customizable Area End

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area End
}
