export const analayticlogo = require("../assets/analayticsLogo.png");
export const qouteSoldLogo = require("../assets/qouteSold.png");
export const totalRevenueLogo = require("../assets/totalRevenue.png");
export const quotePriceLogo = require("../assets/quotePrice.png");

export const innerimg1 = require("../assets/innerimg1.svg");
export const outerimg1 = require("../assets/outerimg1.svg");
export const soldimg = require("../assets/soldimg.svg");
export const revenueimg = require("../assets/revenueimg.svg");
export const priceimg = require("../assets/priceimg.svg");
export const navbarImg = require("../assets/navbarImg.png");
export const mobileviewImg = require("../assets/mobileviewImg.png");

export const backgroundImage=require("../assets/backgroundImage.png");
export const gradientImage=require("../assets/gradientImage.png");
export const mobileBelowImage=require("../assets/mobileBelowImage.png")
export const hawker = require("../assets/hawker.png")
export const logo = require("../assets/logo.png");















