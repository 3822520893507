import React, { useState } from "react";
import { styled } from "@mui/styles";
import {
    Box,
    AppBar,
    Toolbar,
    Button,
} from "@mui/material";
import { analyticsIcon, bciImage, createClientIcon, previousQoutesIcon, support } from "./assets";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { Message } from "../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { removeStorageData } from "../../framework/src/Utilities";


export interface Props {
    navigation: any;
    id: string;
    navigateToAnyPage?: (pathUrl: string) => void

    // Customizable Area End
}

interface S {
    // Customizable Area Start
     currentRoute:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NavigationMenuBar extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props:Props){
       super(props);
       this.state={ currentRoute:props.navigation.state.routeName.replace("/","")}
    }
    handleButtonClick = (pathUrl: string) => {
      const  message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData( getName(MessageEnum.NavigationTargetMessage), pathUrl);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        this.send(message);
        removeStorageData("quoteId")
    };
    render() {
        const {currentRoute}=this.state
         return (
            <NavOuterBox>
                <AppBar sx={webStyle.appBar}>
                    <Toolbar style={{
                        display: "flex", justifyContent: "space-between", width: "90%", flexWrap: "wrap"
                    }}>
                        <Box style={{ marginLeft: "3rem" }}>
                            <img src={bciImage} alt="img" style={{
                                width: "107px"
                            }} />
                        </Box>
    
                        <Box style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                            <Button
                                color="inherit"
                                variant="contained"
                                sx={webStyle.navigationButton(currentRoute.includes("CreateClient"))}
                                onClick={() => this.handleButtonClick('CreateClient')}
                            >
                                <span>
                                    <img src={createClientIcon} alt="" />
                                </span>
                                Create Client
                            </Button>
    
                            <Button
                                color="inherit"
                                sx={webStyle.navigationButton(currentRoute.includes("PreviousQuotes"))}
                                onClick={() => this.handleButtonClick('PreviousQuotes')}
                            >
                                <span>
                                    <img src={previousQoutesIcon} alt="" />
                                </span>
                                Previous Quotes
                            </Button>
                            <Button
                                color="inherit"
                                sx={webStyle.navigationButton(currentRoute.includes("Analytics"))}
                                onClick={() => this.handleButtonClick('Analytics')}
                            >
                                <span>
                                    <img src={analyticsIcon} alt="" />
                                </span>
                                Analytics
                            </Button>
                        </Box>
    
                        <Box style={{ display: "flex", gap: "0.5rem", color: "white", alignItems: "center", cursor: "pointer" }}>
                            <Button
                                color="inherit"
                                onClick={() => this.handleButtonClick('UserProfileBasicBlock')}
                                sx={webStyle.circleNavigationButton(currentRoute.includes("UserProfileBasicBlock"))}
                            >
                                <SettingsOutlinedIcon style={{ color: "white" }} />
                            </Button>
                            <Button
                                color="inherit"
                                sx={webStyle.circleNavigationButton(currentRoute.includes("Faq"))}
                                onClick={() => this.handleButtonClick('Faq')}
                            >
                                <img src={support} alt="" />
                            </Button>
                        </Box>

                        <Box>
                            <Button sx={webStyle.logoutButton}>
                                <LogoutOutlinedIcon />
                                Log Out
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </NavOuterBox>
        );
};
}

const webStyle = {
    appBar:{ 
        background: "#171717", 
        padding: "12px 0"
    },
    navigationButton:(isCurrent:boolean)=>({
        background:isCurrent? "#C7161D":"transparent",
        display: "flex",
        gap: "15px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
        textTransform: "capitalize",
        '&:hover': {
            backgroundColor: '#C7161D'
        },
    }),
    circleNavigationButton:(isCurrent:boolean)=>({
        border: '1px solid #57534E', borderRadius: "8px", padding: "8px 16px",
        backgroundColor:  isCurrent ? '#C7161D' : 'transparent',
        '&:hover': {
            backgroundColor: '#C7161D'
        },
    }),
    passwordTypo: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        color: "#78716C",
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "noWrap"
    },
    passwordTypo2: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#C7161D",
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "noWrap"
    },
    logoutButton: {
        border: "1px solid #57534E",
        color: "#ffffff",
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "flex-start",
        gap: "16px",
        padding: "8px 16px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
    },
    CheckBoxStyle: {
        width: "16px",
        height: "16px",
        borderRadius: "4px",
        border: "1px solid #78716C",
    },
    checkBoxLabel: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "13.79px",
        color: "#171717"
    },
    checkBoxLabel2: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#78716C"
    },
    hrClasss: {
        width: "100%",
        color: "#D6D3D1",
        margin: "2rem 0rem"
    }
};

const NavOuterBox = styled(Box)({
    "@media (max-width:900px)": {
        display: "none",
    },
    "@media (min-width:720px)": {
    },
});

