import React from "react";

// Customizable Area Start
import Calendar from "react-calendar";
import { ToastContainer } from 'react-toastify';
import { Detail } from "react-calendar/dist/cjs/shared/types";
import 'react-toastify/dist/ReactToastify.css';
 import { 
  Box, 
  Button, 
  Grid, 
  Typography,  
  createTheme,
  ThemeProvider,
  CssBaseline,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton
 } from "@mui/material";
import { 
  Close , 
  FilterAltOutlined
} from "@mui/icons-material";
import { 
  analayticlogo, 
  innerimg1, 
  mobileviewImg, 
  outerimg1, 
  priceimg, 
  qouteSoldLogo, 
  quotePriceLogo, 
  revenueimg, 
  soldimg, 
  totalRevenueLogo, 
  mobileBelowImage,
  backgroundImage,
  gradientImage,
  logo 
} from "./assets";
import { styled } from "@mui/styles";
import Navbar from "../../../../packages/components/src/NavBar.web";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
 const theme = createTheme({
  typography: {
    fontFamily: "Quicksand"
  },
})
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderImage = (title: number) => {
    switch (title) {
      case 0:
        return <Box style={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "130px",
        }}>
          <img src={outerimg1.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />
          <img src={innerimg1.default} style={{ position: "absolute", opacity: "0.4" }} />
        </Box>
      case 1:
        return <img src={soldimg.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />;
      case 2:
        return <img src={revenueimg.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />;
      case 3:
        return <img src={priceimg.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />;
    }
  }

  renderlogo = (title: number) => {
    switch (title) {
      case 0:
        return <img src={analayticlogo} alt="analogo" />
      case 1:
        return <img src={qouteSoldLogo} alt="analogo" />
      case 2:
        return <img src={totalRevenueLogo} alt="analogo" />
      case 3:
        return <img src={quotePriceLogo} alt="analogo" />
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        <style>
          {calendarStyles(this.state.currentFilter === "Day" || this.state.currentFilter === "Week")}
        </style>
        {(() => {
          const { dateValue, isRangeSelected, isAnalyticsScreen } = this.state
           const calendar = (minDetail: Detail | undefined, maxDetail: Detail | undefined) => {
            return <Calendar
              data-test-id="calender"
              onChange={this.changeDate}
              value={dateValue}
              minDetail={minDetail}
              maxDetail={maxDetail}
              selectRange={isRangeSelected}
              maxDate={new Date()}
              formatShortWeekday={this.handleDays}
            />
          }
          {
            return isAnalyticsScreen ? <>
              <Navbar navigateToAnyPage={this.navigateToAnyPage} navigation={this.props.navigation} id="" />
              <NavigationMenu
                navigation={null}
                id=""
                srcURL={mobileviewImg}
              />
              <Box style={webStyle.mainBox}>
                <MainBox>
                  <Box sx={webStyle.headerBox}>
                    <Typography data-test-id="analytics-heading" sx={webStyle.headerTypo}>{configJSON.analytics}</Typography>
                    <Button
                      data-test-id="filter-button"
                      variant="contained"
                      sx={webStyle.buttontypo}
                      endIcon={<FilterAltOutlined />}
                      onClick={this.handleFilter}
                    >
                      {configJSON.filter}
                    </Button>
                  </Box>
                  <Box>
                    <Typography sx={webStyle.typo1}>{configJSON.analyticsDescription}</Typography>
                  </Box>
                  {
                    dateValue &&
                    <Box
                      sx={webStyle.filterDetailsButton}
                    >
                      <Typography data-test-id="selected-filter">
                        {this.handleDateValueSwitch(dateValue)}
                      </Typography>
                      <IconButton data-test-id="filter-option-button" onClick={this.handleRemoveDateValue}>
                        <Close />
                      </IconButton>
                    </Box>
                  }
                  <Grid container spacing={2}>
                    {this.cardStaticData.map((data, index) => (
                      <Grid item key={data.title} xs={6} md={6} lg={6} xl={6} >
                        <CardBox style={webStyle.gridContainer}>
                          <Box sx={webStyle.logoBox}>
                            {this.renderlogo(index)}
                          </Box>
                          <Box style={webStyle.cardNumberBox}>
                            <Typography sx={webStyle.CardNumberTypo}>{data.numberCount}</Typography>
                          </Box>
                          <ImageBox>
                            <TypoBox>
                              <Typography sx={webStyle.CardTextTypo}>{data.title}</Typography>
                            </TypoBox>
                            <ImgBoxStyle sx={webStyle.imgBox}>
                              {this.renderImage(index)}
                            </ImgBoxStyle>
                          </ImageBox>
                        </CardBox>
                      </Grid>
                    ))}
                  </Grid>
                </MainBox>
              </Box>
            </> : <Box>
              <Box sx={styles.imageBox}>
                <Box component={"img"} src={mobileBelowImage} alt="banner image" sx={styles.mobileBannerImage} />
                <Box component={"img"} src={logo} sx={styles.logoImage} />
                <Box component={"img"} src={backgroundImage} sx={styles.bannerImage(0)} />
                <Box component={"img"} src={gradientImage} sx={styles.bannerImage(1)} />
                <Box sx={styles.logoBox}>
                  <Box component={"img"} src={logo} />
                  <Typography sx={styles.logoText}>{configJSON.batteryConceptsInternational}</Typography>
                </Box>
              </Box>
              <Box sx={styles.tableBox}>
                <Typography data-test-id="filters-heading" sx={styles.changeStatusText}>{configJSON.filters}</Typography>
                <RadioGroup
                  value={this.state.currentFilter}
                  onChange={this.handleStatus}
                  data-test-id="radio-group"
                >
                  <Box sx={styles.tobBorderBox} />
                  <FormControlLabel
                    value={configJSON.day}
                    control={<Radio />}
                    sx={styles.radioButton(this.state.currentFilter === configJSON.day, true)}
                    label={configJSON.day}
                  />
                  <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.day)}>
                    <Box sx={styles.calenderWidthBox(true)}>
                      <Calendar
                        onChange={this.changeDate}
                        value={dateValue}
                        selectRange={isRangeSelected}
                        maxDate={new Date()}
                        formatShortWeekday={this.handleDays}
                      />
                    </Box>
                  </Box>
                  <FormControlLabel
                    value={configJSON.week}
                    control={<Radio />}
                    sx={styles.radioButton(this.state.currentFilter === configJSON.week, false)}
                    label={configJSON.week}
                  />
                  <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.week)}>
                    <Box sx={styles.calenderWidthBox(true)}>
                      {calendar("month", "month")}
                    </Box>
                  </Box>
                  <FormControlLabel
                    value={configJSON.month}
                    control={<Radio />}
                    sx={styles.radioButton(this.state.currentFilter === configJSON.month, false)}
                    label={configJSON.month}
                  />
                  <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.month)}>
                    <Box sx={styles.calenderWidthBox(false)}>
                      {calendar("year", "year")}
                    </Box>
                  </Box>
                  <FormControlLabel
                    value={configJSON.year}
                    control={<Radio />}
                    sx={styles.radioButton(this.state.currentFilter === configJSON.year, false)}
                    label={configJSON.year}
                  />
                  <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.year)}>
                    <Box sx={styles.calenderWidthBox(false)}>
                      {calendar("decade", "decade")}
                    </Box>
                  </Box>
                </RadioGroup>
                <Button
                  onClick={this.handleApplyFilter}
                  data-test-id="apply-filter-button"
                  variant="contained"
                  sx={styles.sendButton}>
                  {configJSON.applyFilter}
                </Button>
              </Box>
            </Box>
          }
        })()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const calendarStyles=(isSquareShape:boolean)=>(`
.react-calendar {
  width: 100%;
  background: #F5F5F5;
  line-height: 1.125em;
}
.react-calendar * {
  font-family: 'Quicksand';
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  margin-bottom: 0.3rem;
  padding: 0.1rem;
  width: 50px;
  height: 45px;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation button {
  min-width: 40px;
  background: none;
}
.react-calendar__navigation__label__labelText {
  font-weight: 700;
  font-size: 16px;
  color: #171717;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  text-transform: capitalize;
}
.react-calendar__month-view__weekdays__weekday > abbr{
  color: #B4B4B4;
  width: 5px;
  overflow: hidden;
  text-decoration: none;
}
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #dad7d77e;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: 0.8rem;
}
.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #e6e6e6;
} 

.react-calendar__tile--active {
  background-color: #de8589;
  color: #171717;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #de8589;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:hover {
  color:#FFFFFF;
  font-weight: 700;
  font-size: 12px;
  background-color: #C7161D;
  border-top-left-radius: 20%;
  border-bottom-left-radius: 20%;
}
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:hover {
  background-color: #de8589;
  color:#FFFFFF;
  font-weight: 700;
  font-size: 12px;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
}
.react-calendar__tile--rangeStart>abbr {
  border-radius: 20%;
  background-color: #C7161D;
  height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  text-align: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#FFFFFF
}
.react-calendar__tile--rangeEnd>abbr {
  border-radius: 20%;
  background-color: #C7161D;
  height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  text-align: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#FFFFFF
}
.react-calendar__tile--hoverStart {
  border-top-left-radius: 20%;
  border-bottom-left-radius: 20%;
}
.react-calendar__tile--hoverEnd {
  background-color: #B4B4B4;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
}
.react-calendar__month-view__weekdays{
    border-bottom: 1px solid #E5E0EB;
}
.react-calendar__tile--rangeBothEnds {
  background-color: #C7161D;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar__tile--rangeEnd, .react-calendar__tile--rangeEnd{
border-top-right-radius: 20%;
}
.react-calendar__tile--hoverBothEnds {
background-color: #de8589;
display: flex;
justify-content: center;
align-items: center;
}
.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--hoverStart {
  background-color: #de8589;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}
.react-calendar__tile--active.react-calendar__tile--rangeEnd.react-calendar__tile--hoverEnd {
  background-color: #de8589;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-weight: 500;
  font-size: 25px;
}
.react-calendar__month-view__days .react-calendar__tile--active:nth-child(7n),
.react-calendar__month-view__days .react-calendar__tile--hover:nth-child(7n) {
background-color: #de8589;
}
.react-calendar__month-view__days .react-calendar__tile--active:nth-child(7n+1),
.react-calendar__month-view__days .react-calendar__tile--hover:nth-child(7n+1) {
background-color: #de8589;
}
.react-calendar__tile:disabled {
  color: #B4B4B4;
}
.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    border:0.63px solid #A8A29E;
    display: flex;
    flex:0 0 31.333% !important;
    justify-content: center;
    align-items: center;
    margin: 1.5%;
    font-weight: 400;
    font-size: 12px;
}
.react-calendar__year-view .react-calendar__tile:disabled, .react-calendar__decade-view .react-calendar__tile:disabled {
  border-color: transparent;
}
.react-calendar__navigation button:enabled, .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  color:#C7161D;
  background-color: none;
  background: none;
}
.react-calendar__tile{
 font-size: 14px;
 font-weight: 400;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:hover {
  color:#FFFFFF;
  font-weight: 700;background-color:${!isSquareShape? "#C7161D":"#de8589"}; 
  border-top-left-radius:${!isSquareShape? "0%":"20%"};
  border-bottom-left-radius:${!isSquareShape? "0%":"20%"};
}
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:hover {
  background-color:${!isSquareShape? "#C7161D":"#de8589"}; 
  color:#FFFFFF;
  font-weight: 700;
  font-size: 12px;
   border-top-right-radius: ${!isSquareShape? "0%":"20%"};
  border-bottom-right-radius: ${!isSquareShape? "0%":"20%"};
}
`)
const styles={
  calenderWidthBox:(isMobile:boolean)=>({
    width:isMobile?"300px":"100%"
  }),
  tobBorderBox:{ 
    width: "100%", 
    height: "1px", 
    bgcolor: "#D6D3D1" 
  },
  calendarBox:(isCurrent:boolean)=>({ 
    height: isCurrent ? "340px" : 0, 
    overflow: "hidden", 
    transition: "0.5s ease", 
    width: "100%",
    borderBottom: "1px solid #D6D3D1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    bgcolor:"#F5F5F5"
  }),
  sendButton: {
    my: "5%",
    "&.MuiButtonBase-root.MuiButton-root": {
      bgcolor: "#C7161D",
      width: "100%",
      height: "56px",
      borderRadius: "8px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF"
    }
  },
  radioButton: (isCurrent: boolean,isFirstBox:boolean) => ({
     py: "3%",
    "& .MuiTypography-root": {
      fontWeight: isCurrent ? 700 : 400,
      fontSize: "16px",
      color: "#78716C"
    },
    "& .MuiButtonBase-root.MuiRadio-root.Mui-checked": {
      color: "#C7161D"
    },
    "& .MuiButtonBase-root.MuiRadio-root": {
      color: "#A8A29E",
      height: "16px",
      width: "16px",
      bgcolor: "#E7E5E4",
      borderWidth: "1px",
      mr: "3%",
      ml: "4%"
    }
  }),
  changeStatusText:{
    fontSize:"32px",
    fontWeight:700,
    color:"#171717",
    mt:"8%",
    mb:"6%",
    "& + .MuiFormGroup-root":{
      mb:"5%"
    }
  },
  tableBox: {
    mx: "auto",
    [theme.breakpoints.up("md")]: {
      width: "600px"
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px"
    }
  },
  imageBox: {
    position: "relative",
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileBannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      objectPosition: "top",
      display: "block",
      borderRadius: "25px 25px 0px 0px",
      overflow: "hidden",
    },
  },
  logoBox: {
    position: "absolute",
    zIndex: 2,
     display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
  logoText: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "18px",
    textAlign: "center",
   },
   bannerImage: (zIndexNumber: number) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: zIndexNumber,
    borderRadius: "25px",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  }),
  logoImage: {
    height: "40px",
    width: "90px",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: 'translate(-50%, -150%)',
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "none",
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
}
const webStyle = {
  mainBox:{
    display: "flex", 
    justifyContent: "center" 
  },
  gridContainer:{ 
    paddingBottom: "10px"
  },
  logoBox:{ 
    textAlign: "end" 
  },
  cardNumberBox:{ marginLeft: "10px" },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerTypo: {
    fontFamily: "Quicksand",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    textalign: "left",

  },
  buttontypo: {
    "&.MuiButtonBase-root":{
    textTransform: "capitalize",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    width:"82px",
    height:"32px",
    boxShadow:0,
    borderRadius:"4px",
    bgcolor: "#E7E5E4",
    color: "#171717"}
  },
  filterDetailsButton:{     
   height:"32px",
   borderRadius:"4px",
   bgcolor: "#E7E5E4",
   display:"inline-flex",
   alignItems:"center",
   justifyContent:"center",
   maxWidth:"fit-content",
   pl:"10px",
   "& > * ":{
     fontSize: "12px",
     fontWeight: 700,
     color: "#171717",
   }
 },
  typo1: {
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17.5px",
    color: "rgba(120, 113, 108, 1)"
  },
  CardNumberTypo: {
    fontFamily: "Quicksand",
    fontSize: "40px",
    fontWeight: 700,
    lineHeight: "50px",
    color: "rgba(255, 255, 255, 1)"
  },
  CardTextTypo: {
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    color: "rgba(255, 255, 255, 1)",
    textTransform: "capitalize",
    width: "100%"

  },
  typoBox: {
    maxHeight: "100px",
    display: "flex",
    marginLeft: "10px",
    alignItems: "end",
    width: "50%"
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "100px",
  }
};

const MainBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "600px",
  gap: "32px",
  padding: "0.8rem"
});

const TypoBox = styled(Box)({
  maxHeight: "100px",
  display: "flex",
  marginLeft: "10px",
  alignItems: "end",
  width: "50%",
  "@media (max-width:960px)": {
    position: "absolute",
    bottom: "10px",
    minWidth: "70%",
    zIndex: 1,
  },
  "@media (min-width:720px)": {
    fontWeight: 700,
    fontSize: "30px",
  },
});

const ImageBox = styled(Box)({
  display: "flex",
  width: "100%",
  "@media (max-width:960px)": {
    display: "flex",
    flexDirection: "column"
  },
  "@media (min-width:720px)": {
    fontWeight: 700,
    fontSize: "30px",
  },
});
const CardBox = styled(Box)({
  maxWidth: "292px",
  height: "200px",
  borderRadius: "8px",
  background: "rgba(23, 23, 23, 1)",
  "@media (max-width:960px)": {
    maxWidth: "167px",
    position: "relative"
  },
  "@media (min-width:720px)": {
    fontWeight: 700,
    fontSize: "30px",
  },

});

const ImgBoxStyle = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "130px",
  height: "100px",
  "@media (max-width:960px)": {
    display: "flex !important",
    justifyContent: "end !important",
    width: "95% !important",
  },
  "@media (min-width:720px)": {
  },
});
// Customizable Area End
