import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    TextField,
    ThemeProvider,
    CssBaseline,
    createTheme,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormHelperText,
    Typography,
    Autocomplete,
} from "@mui/material";
import { Formik } from 'formik';
import NavigationMenuBar from "../../../../packages/components/src/NavBar.web";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import {
    FormValues
} from './CustomFormController.web';
import { Search } from "@mui/icons-material";
const theme = createTheme({
    typography: {
        fontFamily: 'Quicksand'
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E7E5E4',
                    borderRadius: "8px",
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E7E5E4',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000000',
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    maxHeight: "224px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#C7161D #D5D4D2",
                    "&::-webkit-scrollbar": {
                        width: "12px",
                        backgroundColor: "#f5f5f5",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        backgroundColor: "#C7161D",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#D5D4D2",
                    },
                    backgroundColor: "#F5F5F4",
                },
            },
        },
    },
});
// Customizable Area End

import NewTruckCustomFormController, {
    Props,
    configJSON
} from "./NewTruckCustomFormController.web";

export default class NewTruckCustomForm extends NewTruckCustomFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { chargerModel } = this.state
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <NavigationMenuBar navigation={this.props.navigation} id={""} />
                <NavigationMenu navigation={this.props.navigation} id="" />
                <Box sx={styles.mainBox}>
                    <Typography data-test-id="heading" sx={styles.heading}>{configJSON.survey}</Typography>
                    <Formik
                        initialValues={this.state.initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleSubmit}
                        data-test-id="custom-form-formik"
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <Box component={"form"} onSubmit={handleSubmit}>
                                {this.singleTextFields.slice(0, 2).map((fullField) => (
                                    <React.Fragment key={fullField.qNo}>
                                        <FormControl fullWidth margin="normal">
                                            <FormLabel sx={styles.formLabel}>{fullField.qNo}. {fullField.question}</FormLabel>
                                            <FormHelperText sx={styles.helperText}>
                                                {this.conditionOneValue(Boolean(touched[fullField.name as keyof typeof touched]), errors[fullField.name as keyof typeof values] as string)}
                                            </FormHelperText>
                                            <TextField
                                                data-test-id={fullField.name}
                                                fullWidth
                                                autoComplete="off"
                                                name={fullField.name}
                                                placeholder={fullField.placeHolder}
                                                value={values[fullField.name as keyof typeof values]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={this.conditionTwoBooleans(Boolean(touched[fullField.name as keyof typeof values]), Boolean(errors[fullField.name as keyof typeof values]))}
                                            />
                                        </FormControl>
                                        <Box component={"hr"} sx={styles.hrLine} />
                                    </React.Fragment>
                                ))}
                                {this.multipleTextFields.slice(0, 1).map((pField) => (
                                    <React.Fragment key={pField.qNo}>
                                        <FormControl fullWidth margin="normal">
                                            <FormLabel sx={styles.formLabel}>{pField.qNo}. {pField.question}</FormLabel>
                                            <FormHelperText data-test-id="batterySize-helper-text" sx={styles.helperText}>
                                                {this.condition(
                                                    this.conditionTwoUndefine(touched?.batterySize?.width, errors?.batterySize?.width),
                                                    this.conditionTwoUndefine(touched?.batterySize?.length, errors?.batterySize?.length),
                                                    this.conditionTwoUndefine(touched?.batterySize?.height, errors?.batterySize?.height))}
                                            </FormHelperText>
                                            <Grid container spacing={1}>
                                                {pField.subFields.map((cField) => (
                                                    <Grid sx={styles.gridFields} item xs={12 / 3} key={cField.childFieldName}>
                                                        <FormLabel sx={styles.secondFormLabel}>{cField.placeHolderAndLabel}</FormLabel>
                                                        <TextField
                                                            fullWidth
                                                            data-test-id={cField.childFieldName}
                                                            autoComplete="off"
                                                            name={"batterySize." + cField.childFieldName}
                                                            placeholder={cField.childFieldName}
                                                            value={values.batterySize && values.batterySize[cField.childFieldName as keyof FormValues["batterySize"]] || ""}
                                                            onChange={(event) => isNaN(Number(event.target.value)) ? event.preventDefault() : handleChange(event)}
                                                            onBlur={handleBlur}
                                                            error={this.conditionTwoBooleans(
                                                                Boolean(touched?.batterySize?.[cField.childFieldName as keyof FormValues["batterySize"]]),
                                                                Boolean(errors?.batterySize?.[cField.childFieldName as keyof FormValues["batterySize"]]))
                                                            }
                                                        />
                                                    </Grid>
                                                ))
                                                }
                                            </Grid>
                                        </FormControl>
                                        <Box component={"hr"} sx={styles.hrLine} />
                                    </React.Fragment>
                                ))}
                                {this.radioGroups.slice(0, 1).map((forkliftRadio) => (
                                    <React.Fragment key={forkliftRadio.qNo}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography sx={styles.formLabel}>{forkliftRadio.qNo}. {forkliftRadio.question}</Typography>
                                            <FormHelperText sx={styles.helperText}>
                                                {this.conditionOneValue(Boolean(touched[forkliftRadio.parentFieldName as keyof typeof touched]), errors[forkliftRadio.parentFieldName as keyof typeof errors]?.toString())}</FormHelperText>
                                            <RadioGroup
                                                name={forkliftRadio.parentFieldName}
                                                value={values[forkliftRadio.parentFieldName as keyof typeof values]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {forkliftRadio.options.map((option) => (
                                                    <FormControlLabel
                                                        key={option.value}
                                                        value={option.value}
                                                        control={<Radio />}
                                                        sx={styles.radioButton(values[forkliftRadio.parentFieldName as keyof typeof values] === option.value)}
                                                        label={option.label}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <Box component={"hr"} sx={styles.hrLine} />
                                    </React.Fragment>
                                ))}
                                <FormControl sx={styles.autoCompleteForm} >
                                    <FormLabel sx={styles.formLabel}>{this.singleTextFields[2].qNo}. {this.singleTextFields[2].question}</FormLabel>
                                    <FormHelperText sx={styles.helperText} data-test-id="charger-model-error-text">
                                        {chargerModel.errorMessage}
                                    </FormHelperText>
                                    <Search sx={styles.searchIcon} />
                                    <Autocomplete
                                        data-test-id="charger-model-autoComplete"
                                        options={this.chargerModels}
                                        popupIcon={null}
                                        clearIcon={null}
                                        value={chargerModel.value}
                                        onChange={this.handleChargerModal}
                                        onBlur={this.handleSubmitForm}
                                        noOptionsText={<Typography sx={styles.noMatchText}>{configJSON.noMatchesFound}</Typography>}
                                        renderInput={(params) => (
                                            <TextField
                                                error={Boolean(chargerModel.errorMessage)}
                                                autoComplete="off"
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                placeholder={this.singleTextFields[2].placeHolder}
                                                sx={styles.autoCompleteField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <Box component={"hr"} sx={styles.hrLine} />
                                {this.singleTextFields.slice(3).map((middleField) => (
                                    <React.Fragment key={middleField.qNo}>
                                        <FormControl fullWidth margin="normal">
                                            <FormLabel sx={styles.formLabel}>{middleField.qNo}. {middleField.question}</FormLabel>
                                            <FormHelperText sx={styles.helperText}>
                                                {this.conditionOneValue(Boolean(touched[middleField.name as keyof typeof touched]), errors[middleField.name as keyof typeof values] as string)}
                                            </FormHelperText>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                name={middleField.name}
                                                placeholder={middleField.placeHolder}
                                                value={values[middleField.name as keyof typeof values]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={this.conditionTwoBooleans(Boolean(touched[middleField.name as keyof typeof values]), Boolean(errors[middleField.name as keyof typeof values]))}
                                            />
                                        </FormControl>
                                        <Box component={"hr"} sx={styles.hrLine} />
                                    </React.Fragment>
                                ))}
                                {this.radioGroups.slice(1).map((radio) => (
                                    <React.Fragment key={radio.qNo}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography sx={styles.formLabel}>{radio.qNo}. {radio.question}</Typography>
                                            <FormHelperText sx={styles.helperText}>
                                                {this.conditionOneValue(Boolean(touched[radio.parentFieldName as keyof typeof touched]), errors[radio.parentFieldName as keyof typeof errors]?.toString())}</FormHelperText>
                                            <RadioGroup
                                                name={radio.parentFieldName}
                                                value={values[radio.parentFieldName as keyof typeof values]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {radio.options.map((option) => (
                                                    <FormControlLabel
                                                        key={option.value}
                                                        value={option.value}
                                                        control={<Radio />}
                                                        sx={styles.radioButton(values[radio.parentFieldName as keyof typeof values] === option.value)}
                                                        label={option.label}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <Box component={"hr"} sx={styles.hrLine} />
                                    </React.Fragment>
                                ))}
                                <Button
                                    onClick={this.handleSubmitForm}
                                    data-test-id="submit-button"
                                    type="submit"
                                    variant="contained"
                                    sx={styles.button}
                                    disabled={isSubmitting}
                                    fullWidth
                                >
                                    {configJSON.continueText}
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={styles.outlinedButton}
                                    disabled={isSubmitting}
                                    fullWidth
                                >
                                    {configJSON.backText}
                                </Button>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    autoCompleteForm: {
        width: "100%",
        position: "relative"
    },
    searchIcon: {
        position: 'absolute',
        top: 73,
        right: 15,
        zIndex: 7
    },
    noMatchText: {
        fontWeight: 500,
        fontSize: "16px",
        color: '#78716C'
    },
    autoCompleteField: {
        width: "100%",
        "& .MuiOutlinedInput-root.MuiAutocomplete-hasPopupIcon": {
            paddingRight: "0px",
            pt: 5
        },
        "& .MuiOutlinedInput-root.MuiAutocomplete-hasClearIcon": {
            paddingRight: "0px",
            display: "none"
        },
    },
    gridFields: {
        display: "flex",
        flexDirection: "column",
        gap: 0.5
    },
    heading: {
        fontWeight: 700,
        fontSize: "32px",
        color: "#171717",
        my: "6%"
    },
    radioButton: (isCurrent: boolean) => ({
        "& .MuiTypography-root": {
            fontWeight: isCurrent ? 700 : 400,
            fontSize: "16px",
            color: "#78716C"
        },
        "& .MuiButtonBase-root.MuiRadio-root.Mui-checked": {
            color: "#C7161D"
        },
        "& .MuiButtonBase-root.MuiRadio-root": {
            color: "#A8A29E",
            height: "16px",
            width: "16px",
            bgcolor: "#E7E5E4",
            borderWidth: "1px",
            mr: "5%",
            ml: "4%"
        }
    }),
    formLabel: {
        fontWeight: 700,
        fontSize: "18px",
        color: "#78716C"
    },
    secondFormLabel: {
        fontWeight: 700,
        fontSize: "14px",
        color: "#78716C",
    },
    helperText: {
        width: "100%",
        textAlign: "end",
        fontSize: "10px",
        height: "24px",
        fontWeight: 700,
        m: "0px",
        mb: "2%",
        color: "#C7161D"
    },
    mainBox: {
        mx: "auto",
        [theme.breakpoints.up("lg")]: {
            mt:"100px"
        },
        [theme.breakpoints.up("md")]: {
           mt:"120px"
        },
        [theme.breakpoints.up("xs")]: {
            width: "343px"
        }
    },
    hrLine: {
        height: "1px",
        color: "#D6D3D1",
        my: "15%"
    },
    button: {
        "&.MuiButton-root": {
            mt: "10%",
            borderRadius: "8px",
            height: "55px",
            width: "100%",
            bgcolor: "#C7161D",
            textTransform: "none",
            fontWeight: 700,
            fontSize: "18px",
            color: "#FFFFFF",
        },
    },
    outlinedButton: {
        "&.MuiButton-root": {
            display: "none",
            mt: "6%",
            borderRadius: "8px",
            height: "55px",
            width: "100%",
            textTransform: "none",
            fontWeight: 700,
            fontSize: "18px",
            borderColor: "#171717",
            color: "#171717",
        },
    },
}
// Customizable Area End
