// Customizable Area Start

import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Tab,
    Tabs,
    TextareaAutosize,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaqController, { Props, TabPanelProps, configJSON } from "./FaqController.web";
import { aboveBannerImage, bciImage, checkedBox, mobileBelowImage, unCheckedBox } from "./assets";
import NavigationMenuBar from "../../../../packages/components/src/NavBar.web";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Formik, FormikTouched } from "formik";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as Yup from "yup";


export default class Faq extends FaqController {
    constructor(props: Props) {
        super(props);
    }
    isSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().required('*required field'),
            lastName: Yup.string().required('*required field'),
            email: Yup.string().required('*required field'),
            question: Yup.string().required('*required field'),
            phone: Yup.string().required('*required field'),
        });
    };

    submit = (values: any, handler: any) => {
        this.setState({
            initialValues: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                question: values.question
            }
        })
        handler.resetForm();
    }

    CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    renderTextfields = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any) => {
        return (
            <>
                <Box style={{ maxWidth: "343px", display: "flex", flexDirection: "column", gap: "16px" }}>
                    <TextFiledMAinBox>
                        <Box sx={webStyle.textFieldTypo}>
                            First Name
                            {errors.firstName && touched.firstName ? (
                                <div className="errorclass">
                                    {errors.firstName}</div>) : null}
                        </Box>
                        <TextField
                            sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    backgroundColor: "#E7E5E4",
                                    fontFamily: "Quicksand",
                                },
                                ...(errors.firstName && touched.firstName && {
                                    "& fieldset": {
                                        border: "1px solid red !important",
                                        borderColor: "#C7161D !important",
                                        fontFamily: "Quicksand !important",
                                    },
                                }),

                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            }}
                            placeholder="Your First Name"
                            name="firstName"
                            data-test-id="passwordId"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                        />
                    </TextFiledMAinBox>
                    <TextFiledMAinBox>

                        <Box sx={webStyle.textFieldTypo}>
                            Last Name
                            {errors.lastName && touched.lastName ? (
                                <div className="errorclass">
                                    {errors.lastName}</div>) : null}
                        </Box>
                        <TextField
                            sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    backgroundColor: "#E7E5E4",
                                    fontFamily: "Quicksand",
                                },
                                ...(errors.lastName && touched.lastName && {
                                    "& fieldset": {
                                        border: "1px solid red !important",
                                        borderColor: "#C7161D !important",
                                        fontFamily: "Quicksand !important",
                                    },
                                }),

                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",

                                },
                            }}
                            placeholder="Your Last Name"
                            name="lastName"
                            data-test-id="passwordId"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                        />
                    </TextFiledMAinBox>
                    <TextFiledMAinBox>

                        <Box sx={webStyle.textFieldTypo}>
                            Email
                            {errors.email && touched.email ? (
                                <div className="errorclass">
                                    {errors.email}</div>) : null}
                        </Box>
                        <TextField
                            sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    backgroundColor: "#E7E5E4",
                                    fontFamily: "Quicksand",
                                },
                                ...(errors.email && touched.email && {
                                    "& fieldset": {
                                        border: "1px solid red !important",
                                        borderColor: "#C7161D !important",
                                        fontFamily: "Quicksand !important",
                                    },
                                }),

                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            }}
                            placeholder="Your email"
                            name="email"
                            data-test-id="passwordId"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                        />
                    </TextFiledMAinBox>
                </Box >
            </>
        )

    }
    renderTextfieldTwo = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any) => {
        return (
            <>
                <Box style={{ maxWidth: "343px", display: "flex", flexDirection: "column", gap: "16px" }}>
                    <TextFiledMAinBox>

                        <Box sx={webStyle.textFieldTypo}>
                            Phone Number
                            {errors.phone && touched.phone ? (
                                <div className="errorclass">
                                    {errors.phone}</div>) : null}
                        </Box>
                        <Box sx={webStyle.phoneField}>
                            <Box style={{ position: "relative", width: "70px", height: "50px" }}>
                                <CustomSelectBox
                                data-test-id="dropdown"
                                    onClick={this.toggleDropdown}
                                >
                                    {this.state.selectedValue}
                                    <span style={{ transform: this.state.isOpen ? "rotate(180deg)" : "rotate(0deg)" }}>
                                        <KeyboardArrowDownIcon />
                                    </span>
                                </CustomSelectBox>

                                {this.state.isOpen && (
                                    <ul
                                        style={{
                                            position: "absolute", 
                                            top: "100%",
                                            left: "0",
                                            width: "100%",
                                            border: "1px solid #ccc",
                                            borderRadius: "8px",
                                            backgroundColor: "#fff",
                                            margin: 0,
                                            padding: "8px 0",
                                            listStyle: "none",
                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                            zIndex: 1,
                                        }}
                                    >
                                        {this.state.options.map((option) => (
                                            <li
                                                key={option}
                                                onClick={() => this.selectValue(option)}
                                                style={{
                                                    padding: "8px 16px",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        option === this.state.selectedValue ? "#E7E5E4" : "transparent",
                                                }}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </Box>
                            <TextField
                                sx={{
                                    width: "78%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#E7E5E4",
                                        fontFamily: "Quicksand",
                                    },
                                    ...(errors.phone && touched.phone && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            borderColor: "#C7161D !important",
                                            fontFamily: "Quicksand !important",
                                        },
                                    }),

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="Your Phone Number"
                                name="phone"
                                data-test-id="passwordId"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </TextFiledMAinBox >
                    <TextFiledMAinBox>

                        <Box sx={webStyle.textFieldTypo}>
                            Your question
                            {errors.question && touched.question ? (
                                <div className="errorclass">
                                    {errors.question}</div>) : null}
                        </Box>
                        <TextareaAuto
                            style={{
                                fontFamily: "Quicksand",
                                fontWeight: 400,
                                borderRadius: "8px",
                                backgroundColor: "#E7E5E4",

                                ...(errors.question && touched.question && {
                                    "& fieldset": {
                                        border: "1px solid red !important",
                                        borderColor: "#C7161D !important",
                                        fontFamily: "Quicksand !important",
                                    },
                                }),

                            }}
                            placeholder="Tell us how can we help you"
                            name="question"
                            data-test-id="passwordId"
                            value={values.question}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={(errors.question && touched.question) ? "error" : ""}
                        />
                    </TextFiledMAinBox>
                    <Box sx={webStyle.termsAndPrivacyMainBox}>
                        <Checkbox
                            data-test-id="checkBox"
                            sx={webStyle.checkBox}
                            onChange={this.handleClickTermsAndPrivacy}
                            value={this.state.isCheckedTermsPrivacy}
                            checkedIcon={<Box component={"img"} src={checkedBox} />}
                            icon={<Box component={"img"} src={unCheckedBox} />}
                        />
                        <Typography sx={webStyle.termsAndPrivacyText}>
                            {configJSON.iAgreeWithText}
                            <Typography data-test-id="terms-and-conditions-text" component={"a"} onClick={() => this.handleNavigate("TermsConditions")} sx={webStyle.navigateText}>{configJSON.tryWith} {configJSON.termsAndConditionsText}</Typography>
                            {configJSON.andText}
                            <Typography data-test-id="privacy-and-policy-text" component={"a"} sx={webStyle.navigateText}>{configJSON.tryWith} {configJSON.privacyPolicy}</Typography>
                        </Typography>
                    </Box>
                </Box >
            </>
        )

    }
    render() {
        return (
            <>

                <NavigationMenuBar navigateToAnyPage={this.navigateToAnyPage} navigation={this.props.navigation} id="" data-test-id = "testcase1"/>
                <ImageHeaderBox>
                    <img src={mobileBelowImage} style={{ width: "100%", height: "100%" }} />
                    <img src={aboveBannerImage} style={{ width: "100%", height: "100%" }} />
                    <Box
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}
                    />
                    <BackArrowIconBox>
                        <ArrowBackIcon />
                    </BackArrowIconBox>
                    <Logo>
                        <img src={bciImage} style={{ width: "100%" }} />
                    </Logo>
                </ImageHeaderBox>

                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "3rem" }}>
                    <TabBox>
                        <CustomTabs
                            data-test-id="tabChange"
                            value={this.state.selectedEventTab}
                            onChange={this.handleEventTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example">
                            <Tab
                                label="FAQ ´ s"
                                sx={{
                                    ...webStyle.Tabstyle,
                                    fontWeight: this.state.selectedEventTab === 0 ? 700 : 400,
                                }}
                            />
                            <Tab
                                label="Contact Us"
                                sx={{
                                    ...webStyle.Tabstyle,
                                    fontWeight: this.state.selectedEventTab === 1 ? 700 : 400,
                                }}
                            />
                        </CustomTabs>
                    </TabBox>

                    <this.CustomTabPanel value={this.state.selectedEventTab} index={0}>
                        <MainBox>
                            <Box sx={webStyle.headerBox}>
                                <Typography sx={webStyle.headerDesign}>FAQ</Typography>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                {this.faqData.map((item, index) => (
                                    <Box key={index} sx={webStyle.questionBox}>
                                        <Accordion sx={{
                                            backgroundColor: "#F5F5F4",
                                        }}>
                                            <AccordionSummary sx={{ height: "65px" }}
                                                expandIcon={<ExpandMoreIcon sx={{ cursor: "pointer", color: "#C7161D" }} />}
                                            >
                                                <Typography sx={webStyle.headerTypo}>
                                                    {item.question}
                                                </Typography>
                                            </AccordionSummary>
                                            <hr style={{ width: "90%", backgroundColor: "#C7161D", height: "1px", border: "none" }} />
                                            <AccordionDetails sx={webStyle.accordionDetails}>

                                                <Typography sx={webStyle.answerTypo}>
                                                    {item.answer}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                ))}
                            </Box>
                        </MainBox >
                    </this.CustomTabPanel>

                    <this.CustomTabPanel value={this.state.selectedEventTab} index={1}>
                        <MainBox>
                            <Box sx={webStyle.headerBox}>
                                <Typography sx={webStyle.headerDesign}> Contact Us</Typography>
                            </Box>
                            <Box>
                                <Typography sx={webStyle.contactEmailType}>
                                    You can Contact us via email to
                                </Typography>
                            </Box>
                            <Box>
                                <Accordion sx={{
                                    boxShadow: "none",
                                    "&.MuiPaper-root-MuiAccordion-root": {
                                        boxShadow: "none"
                                    },
                                    "& .MuiAccordionSummary-content": {
                                        display: "flex",
                                        gap: "10px"
                                    }
                                }}>
                                    <AccordionSummary
                                        onClick={() => this.setState({ onArrclick: !this.state.onArrclick })}
                                        expandIcon={<KeyboardArrowRightIcon style={{ rotate: `${this.state.onArrclick ? "270deg" : "0deg"}` }} />}
                                    >

                                        <MailOutlineIcon style={{ color: "#C7161D" }} />
                                        <Typography sx={webStyle.headerTypo}>
                                            contact@johnkenmore.com
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={webStyle.accordionDetails}>
                                        message
                                    </AccordionDetails>
                                </Accordion>
                                <hr style={{ width: "100%", color: "#A8A29E" }} />
                            </Box>

                            <Typography sx={webStyle.messageType}>
                                Or fill out this form and send us your message,  We´ll in touch as soon as possible
                            </Typography>
                            <Formik
                                data-test-id="userboxForm"
                                initialValues={this.state.initialValues}
                                validationSchema={this.isSchema()}
                                onSubmit={this.submit}
                            >
                                {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => {
                                    return (

                                        <Box className="formclass" >
                                            {this.renderTextfields(errors, touched, values, handleChange, handleBlur)}
                                            {this.renderTextfieldTwo(errors, touched, values, handleChange, handleBlur)}
                                            <Box style={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "343px", width: "100%" }}>
                                                <Button type="submit" className="btn1" data-test-id="delete-account1" onClick={() => handleSubmit()} > Submit</Button>
                                            </Box>
                                        </Box>
                                    )
                                }}
                            </Formik>
                        </MainBox>
                    </this.CustomTabPanel>
                </Box >
            </>
        );
    }
}

const webStyle = {
    Tabstyle: {
        width: "284px",
        textTransform: 'capitalize',
        color: "#171717 !important",
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 400
    },
    textFieldTypo: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#78716C",
        display: "flex",
        justifyContent: "space-between"
    },
    phoneField: {
        display: "flex",
        justifyContent: "space-between"
    },
    headerBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    accordionBox: {
        backgroundColor: "#F5F5F4",
    },
    accordionDetails: {
        marginTop: "8px",
        paddingTop: "8px",
    },
    contactEmailType: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 700,
        color: "#78716C",
        lineHeight: "24px",
    },
    messageType: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 700,
        color: "#78716C",
        lineHeight: "24px",
    },
    headerDesign: {
        fontFamily: "Quicksand",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
        textAlign: "left",
    },
    headerTypo: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        marginBottom: "2px"
    },
    questionBox: {
        width: "343px",
    },
    answerTypo: {
        fontFamily: "Quicksand",
        fontWeight: 400,
        lineHeight: '24px',
    },
    textAutoSizeStyle: {
        height: "112px",
        width: "100%",
        minHeight: "112px",
        maxHeight: "112px",
        minWidth: "100%",
        maxWidth: "100%",
        backgroundColor: "#E7E5E4",
        resize: "none" as "none",
        border: "none !important",
        borderRadius: "8px",
        padding: "1rem",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Quicksand",
    },
    termsAndPrivacyMainBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        my: "6%",
        gap: "2%",
        width: "300px",
        mx: "auto"
    },
    checkBox: {
        width: "16px",
        height: "16px"
    },
    termsAndPrivacyText: {
        fontSize: "10px",
        fontWeight: 400,
        color: "#78716C"
    },
    navigateText: {
        fontWeight: 700,
        pb: 0.5,
        fontSize: "10px",
    },
};

const MainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "343px",
    gap: "32px",
    padding: "0.8rem",
    "& .formclass": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    "& .btn1": {
        color: "White",
        backgroundColor: "#C7161D !important",
        borderRadius: "8px",
        height: "55px",
        textTransform: "capitalize",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        border: "none"
    },
});
const TabBox = styled(Box)({
    background: "rgba(245, 245, 244, 1)",
    width: "600px",
    display: "flex",
    justifyContent: "center"

})
const CustomSelectBox = styled(Box)({
    borderRadius: "8px",
    padding: "8px",
    backgroundColor: "#E7E5E4",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    height: "38px",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 400,
    color: "#78716C"

})
const TextareaAuto = styled(TextareaAutosize)({
    height: "112px",
    width: "100%",
    minHeight: "112px",
    maxHeight: "112px",
    minWidth: "100%",
    maxWidth: "100%",
    backgroundColor: "#E7E5E4",
    resize: "none" as "none",
    border: 0,
    borderRadius: "8px",
    padding: "1rem",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Quicksand",
    "&.error": {
        border: "1px solid #C7161D"
    },
    "&.error:focus": {
        border: "1px solid #C7161D"
    },
    "&:hover": {
    },
    "&:focus": {
        border: '0'
    },
    // firefox
    "&:focus-visible": {
        outline: 0
    }
})
const CustomTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
        backgroundColor: '#C7161D',
        width: "264px",
        height: "2px",
        borderRadius: "8px",
        marginBottom: "8px",
        "& .MuiTabs-flexContainer": {
            display: "flex",
            justifyContent: "space-between"
        }
    }
})
const TextFiledMAinBox = styled(Box)({
    maxWidth: "343px",
    "& .errorclass": {
        color: "#C7161D",
        fontFamily: "Quicksand",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left"
    },
})
const ImageHeaderBox = styled(Box)({
    width: "100%",
    borderRadius: "0px 0px 0px 32px",
    opacity: "1",
    position: "relative",
    overflow: "hidden",
    display: "none",
    "@media (max-width:960px)": {
        display: "block",
        width: "100%",
        borderRadius: "0px 0px 0px 32px",
        opacity: "1",
        position: "relative",
        overflow: "hidden",
        height: "160px"
    },
});

const BackArrowIconBox = styled(Box)({
    position: "absolute",
    top: "18%",
    left: "2%",
    color: "white",
    cursor: "pointer",
    zIndex: '1000',
});

const Logo = styled(Box)({
    position: "absolute",
    left: "50%",
    top: "20%",
    transform: "translate(-50%,-50%)",
    width: "15%"
});

// Customizable Area End