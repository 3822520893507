import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import * as Yup from 'yup';
import React from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export interface FormValues {
  forkliftBrand: string;
  forkliftModel: string;
  batterySize:{
    width?: string;
    length?: string;
    height?: string;
  };
  forkliftVoltage:string;
  buildingVolts: string;
  chargerVoltage:string;
  phase:string;
  chargerConnector:string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  initialValues:FormValues;
  chargerModel:{
    value:string,
    errorMessage:string
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NewTruckCustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      initialValues: {
        forkliftBrand: '',
        forkliftModel: '',
        batterySize: {
          width: '',
          length: '', 
          height: '' 
        },
        forkliftVoltage: "",
        buildingVolts: '',
        chargerVoltage: "",
        phase: "",
        chargerConnector: ''
      },
      chargerModel: { 
        value: "", 
        errorMessage: "" 
      }
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    forkliftBrand: Yup.string().required('*required field'),
    forkliftModel: Yup.string().required('*required field'),
    batterySize: Yup.object().shape({
      width: Yup.number().required('*required field'),
      length: Yup.number().required('*required field'),
      height: Yup.number().required('*required field'),
    }),
    forkliftVoltage: Yup.string().required('*required field'),
    buildingVolts: Yup.string().required('*required field'),
    chargerVoltage: Yup.string().required('*required field'),
    phase: Yup.string().required('*required field'),
    chargerConnector: Yup.string().required('*required field'),
  });
  handleSubmit = (values: FormValues) => {
    if(this.state.chargerModel.value){
      const  message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData( getName(MessageEnum.NavigationTargetMessage), "CreateClientAccessories");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
    }
  };
  handleSubmitForm = () => {
    const { chargerModel } = this.state
    if (!chargerModel.value) {
      this.setState((prev) => ({ ...prev, chargerModel: { value: prev.chargerModel.value, errorMessage: configJSON.requiredField } }))
    }
  }
  handleChargerModal = (event: React.SyntheticEvent, modelValue: string | null) => {
    this.setState({
      chargerModel: {
        value: modelValue!,
        errorMessage: Boolean(modelValue) ? "" : configJSON.requiredField
      }
    })
  }
  singleTextFields: { qNo: number, question: string, name: string, placeHolder: string }[] = [
    { qNo: 1, question: configJSON.whatIsForkliftBrand, name: "forkliftBrand", placeHolder: configJSON.answerText },
    { qNo: 2, question: configJSON.whatIsForkliftModel, name: "forkliftModel", placeHolder: configJSON.answerText },
    { qNo: 5, question: configJSON.chargersModel, name: "chargersModel", placeHolder: configJSON.answerText },
    { qNo: 6, question: configJSON.chargerConnector, name: "chargerConnector", placeHolder: configJSON.answerText },
  ];
  multipleTextFields = [
    {
      qNo: 3,
      question: configJSON.batterySize,
      parentFieldName: "batterySize",
      subFields: [
        {
          childFieldName: "width",
          placeHolderAndLabel: "Width"
        },
        {
          childFieldName: "length",
          placeHolderAndLabel: "Length"
        },
        {
          childFieldName: "height",
          placeHolderAndLabel: "Height"
        }
      ]
    },

  ]
  radioGroups = [
    {
      qNo: 4,
      question: configJSON.forkliftVoltage,
      parentFieldName: "forkliftVoltage",
      options: [
        { value: '12', label: '12 Volts' },
        { value: '24', label: '24 Volts' },
        { value: '36', label: '36 Volts' },
        { value: '48', label: '48 Volts' },
        { value: '72', label: '72 Volts' },
        { value: '84', label: '84 Volts' },
      ]
    },
    {
      qNo: 7,
      question: configJSON.buildingVolts,
      parentFieldName: "buildingVolts",
      options: [
        { value: '240', label: '240 Volts' },
        { value: '440', label: '440 Volts' },
        { value: '480', label: '480 Volts' },
        { value: '600', label: '600 Volts' },
      ]
    },
    {
      qNo: 8,
      question: configJSON.chargerVoltage,
      parentFieldName: "chargerVoltage",
      options: [
        { value: '12', label: '12 Volts' },
        { value: '24', label: '24 Volts' },
        { value: '36', label: '36 Volts' },
        { value: '48', label: '48 Volts' },
        { value: '72', label: '72 Volts' },
        { value: '84', label: '84 Volts' },
      ]
    },
    {
      qNo: 9,
      question: configJSON.phase,
      parentFieldName: "phase",
      options: [
        { value: 'Single Phase', label: 'Single Phase' },
        { value: '3 Phase', label: '3 Phase' },
      ]
    }
  ]
  chargerModels = [
    "Model 1 - G", "Model 1 - GP", "Model 2 - G", "Model 2 - GP"
  ]
  condition = (first: boolean, second: boolean, third: boolean) => {
    if (first || second || third) return configJSON.requiredField
  }
  conditionTwoValues = (first: boolean, second: boolean) => {
    if (first || second) return configJSON.requiredField
  }
  conditionOneValue = (booleanValue: boolean, value: string | undefined) => {
    if (booleanValue) return value
  }
  conditionTwoBooleans = (first: boolean, second: boolean) => {
    if (first && second) {
      return true
    } else {
      return false
    }
  }
  conditionTwoUndefine = (first: boolean | undefined, second: string | undefined) => {
    if (first && second) {
      return true
    } else {
      return false
    }
  }
  // Customizable Area End
}
