// App.js - WEB
import 'react-multi-carousel/lib/styles.css';
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import HomeScreenAccountApproved from '../../blocks/navigationmenu/src/HomeScreenAccountApproved.web'
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics.web";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import Faq from "../../blocks/helpcentre/src/Faq.web.tsx";

import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web.tsx";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web.tsx";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Emaildeliverability from "../../blocks/emaildeliverability/src/Emaildeliverability";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import Cfquotebuilder from "../../blocks/cfquotebuilder/src/Cfquotebuilder";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Surveys from "../../blocks/surveys/src/Surveys.web.tsx";
import SurveyForm from "../../blocks/surveys/src/SurveyForm.web.tsx";
import Accessories from "../../blocks/surveys/src/Accesories.web.tsx"
import PreviousQuotes from "../../blocks/surveys/src/PreviousQuotes.web.tsx";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import TnCandPrivacyPolicy from "../../blocks/termsconditions/src/TnCandPrivacyPolicy.web.tsx";
import NewTruckCustomForm from "../../blocks/customform/src/NewTruckCustomForm.web.tsx";

const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
TnCandPrivacyPolicy: {
  component: TnCandPrivacyPolicy,
  path: "/TnCandPrivacyPolicy"
},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
Faq:{
  component:Faq,
 path:"/Faq"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/Login"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Cfquotebuilder:{
 component:Cfquotebuilder,
path:"/Cfquotebuilder"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
CreateClient:{
  component:Surveys,
 path:"/CreateClient",
},
CreateClientSurveyForm:{
  component:SurveyForm,
 path:"/CreateClientSurveyForm",
},
CreateClientNewTruckForm: {
  component: NewTruckCustomForm,
  path: "/CreateClientNewTruckForm"
},
CreateClientExistingTruckForm: {
  component: CustomForm,
  path: "/CreateClientExistingTruckForm"
},
CreateClientAccessories:{
  component:Accessories,
 path:"/CreateClientAccessories",
 exact: true,
},
 PreviousQuotes:{
  component:PreviousQuotes,
 path:"/PreviousQuotes"},
 PreviousQuotesQuoteDetails:{
  component:Emaildeliverability,
 path:"/PreviousQuotesQuoteDetails"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  HomeScreenAccountApproved: {
    component: HomeScreenAccountApproved,
    path: '/HomeScreenAccountApproved',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh'}}>
                <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;