import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface StaticfaqData {
    question: string;
    answer:string
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  export interface InitialValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    question: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation:any;
    id:string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any;
    selectedEventTab:number;
    initialValues:InitialValues;
    isCheckedTermsPrivacy: boolean;
    onArrclick:boolean,
    selectedValue: string;
    options: string[];
    isOpen: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class FaqController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetDataCallId: string = "";
    faqData:StaticfaqData[] = [
        {
            question: "How do I create a new client in the app?",
            answer: "To create a new client, go to the Clients section, tap on Add New Client, and fill out the required fields with client details. Once saved, the client will be listed in your directory and available for future quotes."
        },
        {
            question: "What is the process for generating a quote for a client?",
            answer: "After selecting a client, go to the Quotes section and click Create New Quote You'll be guided through a detailed survey to gather all necessary information about the model, battery, charger, and forklift requirements. Once completed, save the quote to review or edit before finalizing."
        },
        {
            question: "How can I check the status of my quotes?",
            answer: "In the Quotes section, you’ll see all quotes listed with their current statuses: Draft, Pending, Sold, or Declined. You can filter or search to quickly locate specific quotes based on their status."
        },
        {
            question: "What types of analytics are available in the app?",
            answer: 'The app provides analytics such as total quotes generated, average quotes sold, and total revenue. Go to the "Analytics" section to view performance summaries and trends, helping you track your sales success and identify growth opportunities.'
        },
        {
            question: "Can I view previous quotes and update them if needed?",
            answer: "Yes, you can view and edit previous quotes by selecting the 'Quotes' section and finding the relevant quote. Tap on it to make adjustments, then save the changes. Note that edits may affect the quote's status, so check for any updates after editing."
        }
    ];


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            selectedEventTab:0,
            initialValues: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                question: "",
            },
            isCheckedTermsPrivacy: false,
            onArrclick:false,
            selectedValue: "+123", // Default value
            options: ["+123", "+456", "+789"], // List of options,
            isOpen: false,

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    navigateToAnyPage = (pathUrl: string) => {
        const gotoOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
        gotoOrders.addData(getName(MessageEnum.NavigationTargetMessage), pathUrl);
        gotoOrders.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(gotoOrders);
      }
      
   

      a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      handleEventTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ selectedEventTab: newValue });
      };
      handleNavigate = (route: string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          route
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        this.send(message);
      };
      handleClickTermsAndPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isCheckedTermsPrivacy: event.target.checked });
      };
      toggleDropdown = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
      };
    
      selectValue = (value:any) => {
        this.setState({ selectedValue: value, isOpen: false });
      };
    
    // Customizable Area End

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area End
}
